<template>
  <div class="login-container-form">
      <div class="login-container__form-text">
        <h1>Olá! O time da wap.store <br> te dá boas vindas!</h1>
        <p>Faça login para acompanhar o seu projeto</p>
      </div>

      <CommonErrorMessage 
        :show="
          authenticationError || 
          (emailError || passwordError)
        "
        :message="errorMessage" 
        class="login__error-message"
      />
      

      <div>
        <form @submit.prevent="handleLogin">
          <label 
            :class="{
              error: emailError
            }" 
            for="email"
          >
            Informe seu e-mail*
            <input 
              v-model="email"
              type="text" 
              name="email" 
              id="email"
              placeholder="Digite seu e-mail"
            >
          </label>
          <label 
            :class="{
              error: passwordError
            }" 
            for="password"
          >
            Senha*
            <input 
              v-model="password"
              type="password" 
              name="password" 
              id="password" 
              placeholder="Digite sua senha"
            >
          </label>
          <!-- <router-link to="/painel"> -->
          <button 
            :class="{
              disabled: (emailError || passwordError) || loading
            }"
            type="submit"
          >
            {{ buttonText }}
          </button>
          <!-- </router-link> -->
          <router-link 
            to="/recuperar-senha"
            class="login-container--btn-recover-password" 
          >
            <!-- @click="recuperarSenha()" -->
            <img 
              src="@/assets/img/tela-inicial/esqueciSenha.svg" 
              alt="Esqueci minha senha"
            >
            Esqueci minha senha
          </router-link>
        </form>
      </div>
      <div class="login-container-form-seguro">
        <span>
          <img src="@/assets/img/tela-inicial/seguro.svg" alt="Acesso seguro 100%">
          Acesso 100% seguro
        </span>
        <p>Seus dados pessoas estão protegidos, todas as <br>
          informações são criptografadas para a sua segurança.
        </p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Login',
  components: {
    CommonErrorMessage: () => import(/* webpackChunkName: "CommonErrorMessage" */ '@/components/common/CommonErrorMessage.vue')
  },
  data() {
    return {
      email: '',
      password: '',
      emailError: false,
      passwordError: false,
      authenticationError: false,
      errorMessage: 'Preencha corretamente as credenciais!',
      loading: false,
    }
  },
  computed: {
    validEmail() {
      const regex = /^[a-z0-9\-_.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
      return regex.test(this.email);
    },
    validPassword() {
      return this.password.length > 1;
    },
    buttonText() {
      return this.loading ? 'Carregando...' : 'Continuar';
    }
  },
  watch: {
    email() {
      this.authenticationError = false;
      this.emailError = !this.validEmail;
    },
    password() {
      this.authenticationError = false;
      this.passwordError = !this.validPassword;
    }
  },    
  methods: {
    recuperarSenha() {
      this.$emit('recuperarSenha', true);
    },
    async handleLogin() {
      if (!this.validEmail || !this.validPassword) {
        this.emailError = !this.validEmail;
        this.passwordError = !this.validPassword;
      } else {
        this.loading = true;
        const { data } = await this.$axios.post(
          '/usuarios/login', 
          { 
            email: this.email, 
            senha: this.password
          }
        ).catch(err => {
          this.errorMessage = err.data.message;
          this.authenticationError = true;
          return err;
        });
        this.loading = false;
        if (data.token) {
          await this.$store.dispatch('fetchAllUserProjects');
          this.$store.commit('SET_SHOWPOPUPSELECTPROJECT', true);
        }
      }
    }
  },
}
</script>

<style>
.login-container-form .login-container--btn-recover-password {
  font-size: 14px;
  color: #0077DF;
  text-decoration: underline;
  background: transparent;
  height: unset;
  gap: 5px;
  font-weight: normal;
  justify-content: flex-start;
}
</style>

<style scoped>
</style>