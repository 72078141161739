var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container-form"},[_vm._m(0),_c('CommonErrorMessage',{staticClass:"login__error-message",attrs:{"show":_vm.authenticationError || 
        (_vm.emailError || _vm.passwordError),"message":_vm.errorMessage}}),_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleLogin.apply(null, arguments)}}},[_c('label',{class:{
            error: _vm.emailError
          },attrs:{"for":"email"}},[_vm._v(" Informe seu e-mail* "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"text","name":"email","id":"email","placeholder":"Digite seu e-mail"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('label',{class:{
            error: _vm.passwordError
          },attrs:{"for":"password"}},[_vm._v(" Senha* "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","name":"password","id":"password","placeholder":"Digite sua senha"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('button',{class:{
            disabled: (_vm.emailError || _vm.passwordError) || _vm.loading
          },attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]),_c('router-link',{staticClass:"login-container--btn-recover-password",attrs:{"to":"/recuperar-senha"}},[_c('img',{attrs:{"src":require("@/assets/img/tela-inicial/esqueciSenha.svg"),"alt":"Esqueci minha senha"}}),_vm._v(" Esqueci minha senha ")])],1)]),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container__form-text"},[_c('h1',[_vm._v("Olá! O time da wap.store "),_c('br'),_vm._v(" te dá boas vindas!")]),_c('p',[_vm._v("Faça login para acompanhar o seu projeto")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container-form-seguro"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/img/tela-inicial/seguro.svg"),"alt":"Acesso seguro 100%"}}),_vm._v(" Acesso 100% seguro ")]),_c('p',[_vm._v("Seus dados pessoas estão protegidos, todas as "),_c('br'),_vm._v(" informações são criptografadas para a sua segurança. ")])])
}]

export { render, staticRenderFns }